// v-req:delete.confirm 
export default {
    install: function(Vue, options) {
        options = options || { name: 'req' };
        Vue.directive(options.name, {
            inserted: function(el, binding) {
                // Do not initialize when running server side
                if (typeof window !== 'object') return

                el.addEventListener('click', function (e) {
                    e.preventDefault();

                    if (binding.modifiers.confirm) { 
                        let message = binding.value && binding.value.message ? binding.value['message'] : 'Are you sure?';
                        if (!window.confirm(message))
                        {
                            return
                        }
                    }

                    let form = document.createElement("form");
                    let _method = document.createElement("input"); 
                    let _token = document.createElement("input");  

                    form.method = "POST";
                    form.action = this.href;   

                    let method = binding.arg ? binding.arg : 'POST';

                    _method.value = method;
                    _method.name = "_method";
                    form.appendChild(_method);  

                    _token.value = document.head.querySelector('meta[name="csrf-token"]').content;
                    _token.name = "_token";
                    form.appendChild(_token);

                    document.body.appendChild(form);

                    form.submit();
                })
            }
        })
    }
}